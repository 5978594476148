@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  /* font-family: "Roboto", sans-serif; */
  font-family: "Poppins", sans-serif;
}
.editable-field {
  background-color: rgb(231, 222, 222); 
  padding: 1px;
  border-radius: 3px;
  outline: none;
  /* margin: 2px; */
  border: 1px solid white;
}



.editable-field:focus {
  background-color: white; 
  border: 1px solid black;
}
