.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
    background-color: #282828;
    color: white;
    border-radius: 50%;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: max-content;
    /* background-color: rgba(40, 40, 40, 0.7); */
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 99;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s, backdrop-filter 0.3s;
    /* backdrop-filter: blur(5px); */
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: black transparent transparent transparent; /* Match tooltip background */
    transform: translateX(-50%);
  }
  